import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StarsIcon from "@mui/icons-material/Stars";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import useServiceCategory from "../../../hooks/api/useServiceCategory";
import SelectService from "./SelectService";
import { usePaginatedServices } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/configServiceApiHook";
import ServiceCard from "./ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import {
  DELETE_ORDER_BOOK,
  ORDER_BOOK_ENDPOINT,
} from "../../../api/salon-api/log-book-service";
import {
  resetOrderBook,
  setOrderBook,
} from "../../../redux/feature/orderBookSlice";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { RState } from "../../../redux/feature/slice";

function Service() {
  interface RootState {
    UploadLogo: {
      logo: string;
    };
  }

  interface ISelectedService {
    selectedService: {
      services: [
        {
          salonServicesId: number;
          startTime: string;
          cost: number;
          status: number;
          serviceName: string;
        }
      ];
      totalPrice: number;
    };
  }

  interface ISalonDetails {
    SalonDetails: {
      Salon_data: {
        name: string;
      };
    };
  }

  interface ICustomerToken {
    CustomerCred: {
      token: {
        id: number;
        customerName: string;
        uuid: string;
        mobileNo: number;
        email: string;
        gender: string;
        status: string;
        termAndCondition: string;
        salonId: number | null;
        dob: string;
        statusCode: number;
      };
      isCustomer: boolean;
      statusCode: 200;
    };
  }
  interface IAuthToken {
    auth: {
      email: string;
      token: string;
      data: {
        salonId: number;
        clientId: string;
        userRole: string;
        sub: string;
        iat: number;
        exp: number;
      };
      userRole: string;
      salonId: number;
      clientId: string;
    };
  }

  const location = useLocation();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();

  const segment = location.pathname.split("/")[3];
  const Logo = useSelector((state: RootState) => state.UploadLogo.logo);
  const SelectedService = useSelector(
    (state: ISelectedService) => state.selectedService
  );
  const CustomerDetails = useSelector(
    (state: ICustomerToken) => state.CustomerCred.token
  );

  const Auth = useSelector((state: IAuthToken) => state.auth);
  const CustomerName = useSelector((state: RState) => state.CustomerCred?.token?.customerName)

  const SalonDetails = useSelector(
    (state: ISalonDetails) => state?.SalonDetails?.Salon_data
  );
  // console.log(location, segment, "this_is_location");
  console.log("customerCred", CustomerDetails);
  console.log("AuthCred", Auth);

  const [menu, setMenu] = useState(false);
  // const handleOpenMenu = () => setMenu(true);
  const handleCloseMenu = () => setMenu(false);

  const { serviceData } = usePaginatedServices(1, 0, false);
  const { loadingServiceCategory, serviceListData } = useServiceCategory({
    filters: { gender: segment, status: "ACTIVE" },
  });

  console.log(serviceListData, "___data___");

  const navigate = useNavigate();
  if (loadingServiceCategory || !serviceListData) return <div>Loading</div>;

  const handleCheckout = async () => {
    await removeOrderBookDetails();
    // console.log("working");
    if (SelectedService.services.length > 0) {
      const payload = {
        customerId: CustomerDetails.id,
        salonId: Auth?.data?.salonId,
        paidAmount: 0.0,
        totalCost: 0.0,
        discount: 0.0,
        status: "ACTIVE",
        paymentType: "CASH",
        salonServicesId: SelectedService.services.map(
          (item) => item.salonServicesId
        ),
      };

      // console.log("payload: ", payload);
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: ORDER_BOOK_ENDPOINT,
          data: payload,
        });

        // const storePayload = {
        //   id: response?.data?.id,
        //   customerEntity : response?.data?.customerEntity,
        //   paidAmount : response?.data?.paidAmount,
        //   totalCost : response?.data?.totalCost,
        //   discount: response?.data?.discount,
        //   token: response?.data?.token,
        //   assignedStaffIds: response?.data?.assignedStaffIds,
        //   status : response?.data?.status,
        //   paymentType : response?.data?.paymentType,
        //   serviceLogBookEntities : response?.data?.serviceLogBookEntities
        // }
        if (response?.data?.id) {
          dispatch(setOrderBook({ id: response.data.id, data: response.data }));
          window.localStorage.setItem("orderBookId", response.data.id);
          navigate("/customer/checkout");
        }
      } catch (error) {
        console.error("Error fetching Order Book data:", error);
        toast.error("Failed to process order. Please try again.");
      }
    } else {
      toast.warning("Please Select a Service");
    }
  };

  const removeOrderBookDetails = async () => {
    const id = window.localStorage.getItem("orderBookId");
    if (id) {
      try {
        await axiosPrivate({
          method: "DELETE",
          url: `${DELETE_ORDER_BOOK}/${id}`,
        });
        dispatch(resetOrderBook(0));
        window.localStorage.removeItem("orderBookId");
      } catch (error) {
        console.error("Error resetting Order Book data:", error);
      }
    }
  };

  return (
    <>
      <div className="h-screen w-full grid relative">
        <img
          src="/dashboard-background.jpg"
          className="w-full h-full absolute top-0 left-0 z-2 opacity-30"
        />
        <div className="h-[100px] sm:h-[150px] row-span-2 flex justify-center items-center sm:justify-start px-10 z-50">
          <img
            src={Logo}
            className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px]"
          />
        </div>

        <div className="flex justify-between items-center text-4xl px-4 text-center font-bold text-black sm:text-7xl">
        <button
            className="absolute sm:top-8 top-12 right-8 sm:flex sm:text-3xl font-semibold items-center gap-2 cursor-default text-md p-3 rounded-full shadow-md bg-purple-600 text-white"
            onClick={() => navigate('/')}
          >
            <ArrowBackIosIcon fontSize="large" />
          </button>
          <p
            className="sm:hidden font-semibold flex items-center gap-2 cursor-default z-50"
            onClick={() => setMenu(true)}
          >
            <LocalMallIcon fontSize="large" />
          </p>
        </div>

        <div className="h-full sm:w-3/5 bg-slate-100 p-8 flex flex-col justify-between sm:overflow-hidden sm:grid">
          <div className="overflow-hidden w-full">
            <div className="py-7 ">
              <SelectService serviceListData={serviceData} type={segment} />
            </div>
          </div>

          <div className="w-full border-2 border-gray-400 px-3 relative overflow-y-scroll hide-scrollbar rounded-lg">
            <div className="h-full w-full">
              <ServiceCard serviceListData={serviceData} type={segment} />
            </div>
          </div>
        </div>

        <div className="h-full w-2/5 bg-gray-400 p-8 hidden sm:block absolute right-0 top-0">
          <button
            className="absolute sm:top-8 z-50 top-12 right-8 sm:flex sm:text-3xl font-semibold items-center gap-2 cursor-default text-md p-3 rounded-full shadow-md bg-purple-600 text-white"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosIcon fontSize="large" />
          </button>
          <div className=" w-full h-1/3 flex items-start justify-center flex-col">
            <p className="text-4xl font-bold mb-8 text-center">
              {CustomerName.toUpperCase()} WELCOME ONBOARD.
            </p>
            <p className="text-black text-2xl">
              <StarsIcon className="text-black " fontSize="medium" /> 4.89 (5.0
              M Orders)
            </p>
          </div>

          <div className="bg-slate-100 rounded-lg w-full h-2/3 px-2 py-3 sm:grid overflow-hidden">
            <div className="w-full overflow-y-scroll hide-scrollbar border-b-2 border-slate-500 py-4 px-2 text-3xl font-semibold text-slate-600">
              Cart
            </div>
            <div className="w-full h-5/6 overflow-y-scroll py-4 px-2 hide-scrollbar">
              <div className="h-full w-full p-1 rounded-md text-center">
                {SelectedService.services.length > 0 ? (
                  SelectedService.services.map((item, index) => (
                    <div
                      className="flex justify-between items-center w-full py-3 mb-2 bg-slate-300"
                      key={index}
                    >
                      <p className="w-2/5 h-full text-wrap pl-2">
                        {item.serviceName}
                      </p>
                      <p className="text-wrap w-1/5 h-full">
                        &#8377; {item.cost}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400 text-xl italic">Cart is Empty</p>
                )}
              </div>
            </div>
            <div className=" w-full flex justify-start items-start pt-4">
              <button
                className="w-full rounded-md py-4 flex justify-between px-3 opacity-100 bg-blue-500 hover:bg-blue-700 transition duration-500 transform  hover:scale-105 text-white"
                onClick={handleCheckout}
              >
                <p>Checkout</p>
                {SelectedService?.totalPrice && (
                  <p>&#8377; {SelectedService.totalPrice}</p>
                )}
              </button>
            </div>
          </div>
        </div>

        <Modal
          open={menu}
          onClose={handleCloseMenu}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="w-full h-full flex justify-center items-center"
        >
          {/* <Box className="bg-black/[0.6] overflow-y-scroll hide-scrollbar relative"> */}

          <div className="h-full w-full bg-gray-400 p-8 overflow-y-scroll hide-scrollbar relative">
            <div className=" w-full flex items-start m-4 flex-col">
              <p className="font-bold text-xl">
                {SalonDetails?.name} is pleased to serve you.
              </p>
              <p className="text-black text-xs">
                <StarsIcon className="text-black " fontSize="small" /> 4.89 (5.0
                M Orders)
              </p>
            </div>

            <div className="bg-slate-100 rounded-lg w-full px-2 py-3 overflow-hidden">
              <div className="w-full overflow-y-scroll hide-scrollbar border-b-2 border-slate-500 py-4 px-2 text-3xl font-semibold text-slate-600">
                Cart
              </div>
              <div className="w-full h-5/6 overflow-y-scroll py-4 px-2 hide-scrollbar">
                <div className="h-full w-full p-1 rounded-md text-center">
                  {SelectedService.services.length > 0 ? (
                    SelectedService.services.map((item, index) => (
                      <div
                        className="flex justify-between items-center w-full py-3 mb-2 bg-slate-300"
                        key={index}
                      >
                        <p className="w-2/5 h-full text-wrap pl-2">
                          {item.serviceName}
                        </p>
                        <p className="text-wrap w-1/5 h-full">
                          &#8377; {item.cost}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-400 text-xl italic">
                      Cart is Empty
                    </p>
                  )}
                </div>
              </div>
              <div className=" w-full grid gap-4">
                <button
                  className="w-full rounded-md py-4 flex justify-between px-3 text-lg font-medium opacity-100 bg-blue-500 hover:bg-blue-700 transition duration-500 transform hover:opacity-0 hover:scale-150 text-white"
                  onClick={handleCheckout}
                >
                  <p>Checkout</p>
                  {SelectedService?.totalPrice && (
                    <p>&#8377; {SelectedService.totalPrice}</p>
                  )}
                </button>
                <button
                  className="w-full rounded-md py-4 px-3 opacity-100 bg-gray-300 text-lg font-medium transition duration-500 transform hover:opacity-0 hover:scale-150"
                  onClick={() => setMenu(false)}
                >
                  Close Cart
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Service;
