import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";

const columns = [
  {
    width: 150,
    label: "Transaction Amount (₹)",
    dataKey: "transactionAmount",
    numeric: true,
  },
  {
    width: 150,
    label: "Transaction Date",
    dataKey: "transactionDate",
  },
  {
    width: 250,
    label: "Payout Remark",
    dataKey: "payoutRemark",
  },
  //   {
  //     width: 150,
  //     label: 'Balance Amount',
  //     dataKey: 'balanceAmount',
  //     numeric: true,
  //   },
  //   {
  //     width: 150,
  //     label: 'Salary',
  //     dataKey: 'salary',
  //     numeric: true,
  //   },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          //   align={column.numeric ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{ backgroundColor: "background.paper" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell key={column.dataKey}>
          {column.dataKey === "transactionDate"
            ? row[column.dataKey]
            : row[column.dataKey] !== null
            ? row[column.dataKey]
            : "N/A"}
          {/* {column.dataKey === "transactionAmount"
            ? row[column.dataKey] `₹ ${row[column.dataKey]}`
            : "N/A"} */}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

export default function TransactionTable({ transactionTableData }) {
  return (
    <Paper style={{ height: 400, width: "100%" }}>
     { transactionTableData.length > 0 ? <TableVirtuoso
        data={transactionTableData}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    : "No transaction history available"
    }
    </Paper>
  );
}
