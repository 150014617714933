// react
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// MUI
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  Typography,
} from "@mui/material";

// components
import { BaseCard } from "../../../../../components/Mui";
import { EMP_FEEDBACK_DATA, empPayoutSummary } from "./PayoutConstants";
import { CustomizedDialog } from "../../../../../components/Mui/feedback/Dialog";

import { useDispatch, useSelector } from "react-redux";

// icons
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { PayoutEmpTable } from "./PayoutDetails";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import {
  setPayout,
  updatePayout,
} from "../../../../../redux/feature/employeePayoutSlice";
import { STAFF_PAYOUTS_ENDPOINT } from "../../../../../api/salon-api/salon-service";
import { toast } from "react-toastify";
import TransactionTable from "./TransactionTable";

export default function Payout() {
  /**
   * Todo: API integration pending here 23/04/2024
   *  1. By Default 1st employee will be selected
   *  2. User Can select employee from dropdown
   * !Question - Current Redux state not getting required data like emp ratings id etc.
   */

  const [openDialogue, setOpenDialogue] = useState(false);
  const [selectedStaffData, setSelectedStaffData] = useState({});
  const EmployeePayout = useSelector((state) => state.payout);
  const [transactionTableData, setTransactionTableData] = useState([])
  const SalonDetails = useSelector((state) => state?.SalonDetails?.Salon_data);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };
  // const handleDialogueOpen = (event, employee) => {
  //   // this employee will be selected
  //   // console.log(employee)
  //   setSelectedStaffData(employee);
  //   setOpenDialogue(true);
  // };

  const getEmployeePayoutsData = async (personId) => {
    // const isFound = EmployeePayout ? timeSheetData.find((item) => item.id === personId) : false;
    // if (EmployeePayout?.content.length <= 0) {
      try {
        const response = await axiosPrivate({
          method: "POST",
          url: `${STAFF_PAYOUTS_ENDPOINT}/getAllStaffPayouts`,
          data: {
            page: 1,
            size: 1000,
            sotOrder: "desc",
            salonId: SalonDetails?.id,
          },
        });
        dispatch(setPayout(response.data));
        // setTableData(response.data);
      } catch (error) {
        console.error("Error fetching Staff Payout Data:", error);
        toast.error("Error fetching Staff Payout Data");
      }
  // }
  };

  const updateStaffPayoutData = async (payload, row) => {
    try {
      const response = await axiosPrivate({
        method: "POST",
        url: `${STAFF_PAYOUTS_ENDPOINT}`,
        data: { ...payload },
      });

      const data = {
        ...payload,
        ...row,
        balanceAmount: row.balanceAmount - payload.transactionAmount,
      };
      dispatch(updatePayout(data));
    } catch (error) {
      toast.error("Error Updating Staff Payout");
      console.error("Error Updating Staff Payout:", error);
    }
  };

  const getStaffTransactionHistory = async (event, row) => {
    try {
      setSelectedStaffData(row);
      const response = await axiosPrivate({
        method: "POST",
        url: `${STAFF_PAYOUTS_ENDPOINT}/getAllStaffPayoutsByStaffId`,
        data: {
          page: 1,
          size: 1000,
          sortOrder: "DESC",
          staffId: row.staffId,
        },
      });
      setOpenDialogue(true);
      setTransactionTableData(response.data?.content)
    } catch (error) {
      toast.error("Error fetching Transaction History");
      console.error("Error fetching Transaction History:", error);
      return [];
    }
  };

  useEffect(() => {
    getEmployeePayoutsData();
  }, [SalonDetails.id]);

  return (
    <BaseCard sx={{ p: 0 }}>
      {EmployeePayout.content?.length > 0 ? (
        <PayoutEmpTable
          updateStaffPayoutData={updateStaffPayoutData}
          getStaffTransactionHistory={getStaffTransactionHistory}
        />
      ) : (
        "No Data available"
      )}

      <CustomizedDialog
        openDialog={openDialogue}
        handleCloseDialog={handleDialogueClose}
        title={`${selectedStaffData.staffName}'s Transaction History`}
        // disableClose={buttonLoader}
        // icon={<SupervisedUserCircleIcon />}
        maxWidth="sm"
        isActionButtonRequired={false}
      >
        {/* EmpSelector */}
        {/* <EmpSelect /> */}
        {/* Ratings */}
        {/* <div className=" flex items-center w-full max-w-[450px] min-w-72 justify-center  mx-auto flex-col md:flex-row ">
          <Stack direction={"column"}>
            <ResponsiveRatingSummary selectedStaffData={selectedStaffData} transactionTableData={transactionTableData} />
          </Stack>
        </div> */}
        <TransactionTable transactionTableData={transactionTableData}/>
      </CustomizedDialog>
    </BaseCard>
  );
}

/**
 * This function JSX may be use in future
 * Renders a component for selecting an employee from a list.
 *
 * @return {JSX.Element} The JSX element for the employee selection component.
 * @deprecated use {@link EmpSelect}
 */
const EmpSelect = () => {
  const allEmployees =
    useSelector((state) => state.Employee.allEmployees) || [];

  const [employee, setEmployee] = useState("");

  /**
   * Updates the selected employee based on the selected value from the event target.
   *
   * @param {Event} event - The event object containing the target value.
   * @return {void} This function does not return anything.
   */
  const handleChange = (event) => {
    setEmployee(event.target.value);
  };
  return (
    <Stack>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
        <InputLabel id="employees-payout">{EMP_FEEDBACK_DATA.LABEL}</InputLabel>

        <Select
          value={employee}
          label={EMP_FEEDBACK_DATA.LABEL}
          onChange={handleChange}
        >
          {allEmployees.map((item) => (
            <MenuItem key={item.empId} value={item?.empId}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

/**
 * Renders a responsive rating summary component.
 *
 * @return {JSX.Element} The rendered rating summary component.
 */
const ResponsiveRatingSummary = ({ selectedStaffData,transactionTableData }) => {
  return (
    <>
      {empPayoutSummary.map((payout) => (
        // <Ratings key={rating.label} label={rating.label} value={rating.value} />
        <Stack justifyContent="center" alignItems="center" width="100%">
        {/* Assuming empPayoutSummary is an array of data for rendering */}
        {/* {empPayoutSummary.map((payout) => ( */}
          <Box key={payout.label} sx={{ width: '100%', marginBottom: 2 }}>
            {/* Optional: Render payout summary */}
            {/* <Box sx={{ flex: 1, whiteSpace: "nowrap" }}>
              <Typography variant="body1" noWrap>
                {payout.label}
              </Typography>
            </Box> */}

            {/* Table render */}

          </Box>
            <TransactionTable transactionTableData={transactionTableData} />
        {/* ))} */}
      </Stack>
      ))}
    </>
  );
};

// const Ratings = ({ label = '', value }) => {
//   return (
//     <Stack direction={'row'} justifyContent={'space-between'} m={0.5}>
//       <Stack>
//         <Typography component='body2' mr={1}>
//           {label}
//         </Typography>
//       </Stack>
//       <Rating readOnly name={label} value={value} precision={0.5} />
//     </Stack>
//   );
// };

// Ratings.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.number,
// };

// const OverAllRating = ({ label = '', value }) => {
//   return (
//     <Stack>
//       <Typography component='h3' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
//         {EMP_FEEDBACK_DATA.OVER_ALL_RATING}
//       </Typography>
//       <div className='m-1 rounded-full border h-32 w-32 flex justify-center items-center flex-col'>
//         <Stack>
//           <Typography component='body2' sx={{ textAlign: 'center' }}>
//             {EMP_FEEDBACK_DATA.OVER_ALL}
//           </Typography>
//         </Stack>
//         <Typography>{value}</Typography>
//         <Rating name={label} value={value} readOnly size={'small'} precision={0.5} />
//       </div>
//     </Stack>
//   );
// };

// OverAllRating.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.number,
// };
